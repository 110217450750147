<template>
  <AssetHealthStyled>
    <AssetHealthHeaderOrganism v-if="assetWithHealth" :asset="assetWithHealth" :isLoading="$apollo.queries.assetIssues.loading" />
    <router-view v-if="assetWithHealth" :asset="assetWithHealth" />
  </AssetHealthStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { mappedTyreSeverity, healthMapper } from '@/utils/maintenance/healthUtils'

import AssetHealthHeaderOrganism from '../SharedPages/AssetHealth/AssetHealthHeaderOrganism'
import ASSET_WITH_HEALTH_AND_ENGINE_QUERY from '#/graphql/operations/asset/assetWithHealthAndEngine.gql'
import ASSET_WITH_WHEELPOSITION_QUERY from '#/graphql/operations/asset/wheelposition/assetWheelPositionsMounted.gql'
import ASSET_ISSUES_QUERY from '#/graphql/operations/maintenance/assetIssuesQuery.gql'
import { getLast24HoursInterval } from '../../utils/time'

const AssetHealthStyled = styled('div')`
  display: grid;
  min-height: calc(100% - 2rem);
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 1rem;
  margin: 0 1rem 1rem 1rem;
`
export default {
  inject: ['uiSettings', 'permissions'],
  components: {
    AssetHealthHeaderOrganism,
    AssetHealthStyled,
  },
  data() {
    return {
      asset: null,
      assetIssues: [],
    }
  },
  computed: {
    hasTyreReadPermission() {
      return this.permissions?.read.find(p => p.name === 'tyre_read')
    },
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2).toLowerCase() + replacedDates.slice(2, 5)
    },
    assetWithHealth() {
      if (!this.asset?.id) {
        return null
      }
      return {
        ...this.asset,
        assetHealth: this.combinedAssetHealth,
        assetSeverities: this.assetSeverities,
      }
    },
    mappedWheelPositionsData() {
      const filterdWheelPositions = this.wheelPositions ? this.wheelPositions.filter(wheel => wheel.unmountedAt === null) : []
      const wheelPositionsData = filterdWheelPositions.map(wheelPosition => {
        const temperatureServerity = wheelPosition?.temperatureThreshold?.severity || ''
        const pressureThresholdSeverity = wheelPosition?.pressureThreshold?.severity || ''
        const tyreHealth = mappedTyreSeverity(temperatureServerity, pressureThresholdSeverity)
        return tyreHealth
      })
      return wheelPositionsData
    },
    tyreHealth() {
      const health = healthMapper(this.mappedWheelPositionsData)
      return health
    },
    assetHealth() {
      const machineHealth = this.asset?.health ?? []
      const status = machineHealth[0]?.status ?? ''
      return status
    },
    assetSeverities() {
      const activeIssues = this.assetIssues.filter(issue => issue.isActive)
      const assetSeverities = activeIssues.reduce((acc, issue) => {
        acc.add(issue.highestSeverity)
        return acc
      }, new Set())
      // Scenario 1: If there is a tyre health issue, set the severity according to the tyre health.
      // Scenario 2: If the tyre health is green and there are no active asset issues, set the severity to green.
      // Scenario 3: If the tyre health is unknown (user has no permission to view tyre health or no tyre data available) and there are no active asset issues, set the severity to green.
      if (this.tyreHealth !== 'Green' && this.tyreHealth !== 'Unknown') {
        assetSeverities.add(this.tyreHealth)
      } else if ((this.tyreHealth === 'Unknown' || this.tyreHealth === 'Green') && assetSeverities.size === 0) {
        assetSeverities.add('Green')
      }
      return Array.from(assetSeverities)
    },
    combinedAssetHealth() {
      const combined = []
      combined.push(this.tyreHealth)
      combined.push(this.assetHealth)
      const health = healthMapper(combined)
      return health
    },
    last24HoursInterval() {
      return getLast24HoursInterval()
    },
  },

  apollo: {
    asset: {
      query: ASSET_WITH_HEALTH_AND_ENGINE_QUERY,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      skip() {
        return !this.$route.params.id
      },
    },
    wheelPositions: {
      query: ASSET_WITH_WHEELPOSITION_QUERY,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      update: data => data?.assetWheelPositions ?? [],
      skip() {
        return !this.$route.params.id || !this.hasTyreReadPermission
      },
    },
    assetIssues: {
      query: ASSET_ISSUES_QUERY,
      variables() {
        return {
          where: {
            interval: this.last24HoursInterval.toISO(),
            assetIds: [this.$route.params.id],
            locale: this.locale,
          },
        }
      },
      skip() {
        return !this.asset
      },
      update(data) {
        return data.assetIssues
      },
    },
  },
}
</script>
